<script>
export default {
  render() {
    return (
      <div id="app">
        <router-view />
      </div>
    )
  }
}
</script>

<style>
body {
  background: #f6fafd;
}

#app {
  height: 100vh;
  /* min-width: 1366px !important; */
}
</style>
